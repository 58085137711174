<template>
  <div>
    <div id="header">
      <div class="d-flex flex-row justify-content-around logo">
        <img
          src="@/assets/skl-logo.png"
          alt="Smiley face"
          height="27"
          width="70"
          class="mb-2"
        />
      </div>
      <div id="header-title" class="text-center title">
        <span class="font-weight-bold">ตารางค่างวด</span>
      </div>
    </div>
    <div id="content-installment">
      <ListHeader
        :name="profile.customerName"
        :number="profile.contractNo"
        :imgUrl="profile.photo"
      />
      <b-table
        striped
        hover
        :items="items"
        :fields="fields"
        :busy="isBusy"
        show-empty
        empty-text="ไม่พบข้อมูล"
        class="greenColor"
      >
        <template #head(term_No)="data">
          <div class="header-table">
            <span class="w-100">{{ data.label }}</span>
          </div>
        </template>

        <template #head(due_Date)="data">
          <div class="header-table">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #head(inst_Tot_Amt)="data">
          <div class="header-table">
            <span>{{ data.label }}</span>
          </div>
        </template>

        <template #head(act_Tot_Amt)="data">
          <div class="header-table">
            <span>{{ data.label }}</span>
            <!-- <span>ยอดเงินที่ต้องชำระ<br />(บาท)</span> -->
          </div>
        </template>

        <template v-slot:cell(inst_Tot_Amt)="data">
          <p class="mb-0 text-right">
            {{
              Number(data.item.inst_Tot_Amt)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </p>
        </template>
        <template v-slot:cell(act_Tot_Amt)="data">
          <p class="mb-0 text-right">
            {{
              Number(data.item.act_Tot_Amt)
                .toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")
            }}
          </p></template
        >
      </b-table>
      <p class="mb-0 txt-note">
        ข้อมูล ณ วันที่ {{ profile.asOfDate || "-" }} <br />
      </p>
      <div class="display-inline w-100">
        <span class="txt-note"
          >หมายเหตุ : ข้อมูลที่แสดงในตารางเป็นข้อมูลค่างวดย้อนหลัง 3 เดือน
          และข้อมูลค่างวดล่วงหน้า 3 เดือน</span
        >
      </div>
      <!-- <div class="display-inline w-100">
      <p class="title-note">
        หมายเหตุ :
      </p>
      <p class="content-note">
        ข้อมูลที่แสดงในตารางเป็นข้อมูลค่างวดย้อนหลัง 3 เดือน
        และข้อมูลค่างวดล่วงหน้า 3 เดือน
      </p>
    </div> -->

      <div class="my-4">
        <p class="txt-tel">
          สอบถามข้อมูลเพิ่มเติมโทร.
          <a :href="`tel:${callCenter}`"
            ><span
              v-for="(c, i) in callCenter"
              :key="i"
              class="link-tel"
              :class="[{ 'link-tel-green': c == '3' }, 'link-tel']"
              >{{ c }}<span class="link-tel-green"></span></span
          ></a>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ListHeader from "./ListHeader";
export default {
  components: {
    ListHeader
  },
  data: () => {
    return {
      contractNo: "",
      lineId: "",
      lineProfile: {},
      profile: {
        asOfDate: "",
        contractNo: "",
        identificationNo: "",
        customerName: "",
        photo: ""
      },
      callCenter: "",
      isBusy: false,
      items: [],
      fields: [
        {
          key: "term_No",
          label: "งวดที่",
          class: "text-center align-middle"
        },
        {
          key: "due_Date",
          label: "กำหนดชำระ",
          class: "text-center align-middle"
        },
        {
          key: "inst_Tot_Amt",
          label: "ค่างวด (บาท)",
          class: "text-center align-middle"
        },
        {
          key: "act_Tot_Amt",
          label: "ยอดเงินที่ต้องชำระ (บาท)",
          class: "text-center align-middle"
        }
      ]
    };
  },
  beforeCreate() {
    this.$liff
      .init({ liffId: this.$liff_ID_InstallmentList })
      .then(() => {
        if (!this.$liff.isLoggedIn()) {
          this.$liff.login().then(() => {
            this.$liff.getProfile().then(profile => {
              this.lineProfile = profile;
              this.checkTerm(profile.userId);
              // this.checkConsent();
            });
          });
        } else {
          this.$liff.getProfile().then(profile => {
            this.lineProfile = profile;
            this.checkTerm(profile.userId);
            // this.checkConsent();
          });
        }
        this.$liff.getFriendship().then(data => {
          if (!data.friendFlag) {
            this.$liff.openWindow({
              url: `https://line.me/R/ti/p/${this.$liff_Channel_ID}`,
              external: false
            });
            this.$liff.closeWindow();
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  },
  created() {
    this.$isLoadingAlpha = true;
    this.contractNo =
      this.$route.query.contractNo || this.$route.query.ContractNo;
    this.lineId = this.$route.query.LineId;

    if (!this.contractNo) {
      const queryString = decodeURIComponent(window.location.search).replace(
        "?liff.state=",
        ""
      );
      const params = new URLSearchParams(queryString);
      // for (const p of params) {
      //   console.log(p);
      // }
      if (params.has("contractNo")) {
        const contractNo = params.get("contractNo") || params.get("ContractNo");
        const lineId = params.get("LineId");

        this.contractNo = contractNo;
        this.lineId = lineId;
      } else {
        const queryString = decodeURIComponent(window.location.search).replace(
          "liff.state=?",
          ""
        );
        const params = new URLSearchParams(queryString);
        const contractNo = params.get("contractNo") || params.get("ContractNo");
        const lineId = params.get("LineId");

        this.contractNo = contractNo;
        this.lineId = lineId;
      }
    }

    this.getInstallmentList();
  },
  methods: {
    checkTerm(lineId) {
      let myHeaders = new Headers();
      myHeaders.append("API-KEY", this.$API_KEY_DEV_LINE_SKL);
      myHeaders.append("Content-Type", "application/json");
      let requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
      fetch(
        `${this.$API_DEV_LINE_SKL}/customer/checkterms/${lineId}`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            if (!result.detail) {
              // let routeData = this.$router.resolve({
              //   name: "policy",
              //   query: { lineid: lineId }
              // });
              // window.open(routeData.href, "_blank");
              this.$router.push({
                path: "/policy",
                query: {
                  lineid: lineId,
                  term: true
                }
              });
            }
          } else {
            // let routeData = this.$router.resolve({
            //   name: "policy",
            //   query: { lineid: lineId }
            // });
            // window.open(routeData.href, "_blank");
            this.$router.push({
              path: "/policy",
              query: {
                lineid: lineId,
                term: true
              }
            });
          }
        })
        .catch(error => {
          console.error(error);
        });
    },
    async getInstallmentList() {
      this.isBusy = true;

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        LineId: this.lineId,
        ContractNo: this.contractNo
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(
        `${this.$API_DEV_LINE_SKL}/contract/paymentinstallment`,
        requestOptions
      )
        .then(response => response.json())
        .then(result => {
          if (result.result == 1) {
            this.items = result.detail.detail;
            this.profile.asOfDate = result.detail.asOfDate;
            this.profile.contractNo = result.detail.contractNo;
            this.profile.customerName = result.detail.customerName;
            this.callCenter = result.detail.callCenter;
            this.profile.photo = result.detail.photo;
            this.isBusy = false;
            this.$isLoadingAlpha = false;
          } else {
            document.getElementById(
              "content-installment"
            ).innerHTML = `<div class="content_center body f-14">${result.message}</div>`;
            this.$isLoadingAlpha = false;
          }
        })
        .catch(error => {
          this.isBusy = false;
          this.items = [];
          console.error(error);
        });
    }
  }
};
</script>

<style scoped>
::v-deep .greenColor thead {
  background-color: #7fc241 !important;
  color: white;
}
.txt-tel {
  text-align: center;
  font-size: 11px;
}
.txt-note {
  font-size: 11px;
}
.link-tel {
  text-decoration-line: none;
  text-align: center;
  /* color: #212529; */
  color: #f48139;
  font-weight: bold;
}
.link-tel-green {
  color: #7fc241;
}
.display-inline {
  display: inline-flex;
}
.title-note {
  width: 12%;
}
.content-note {
  width: 88%;
}
.header-table,
::v-deep .table td {
  font-size: 14px;
}
::v-deep table > thead > tr > th {
  border: 0.5px solid #cdfca1;
}
::v-deep table > tbody > tr > td {
  border-left: 0.5px solid #cdfca1;
  border-right: 0.5px solid #cdfca1;
}
::v-deep tr:last-child {
  border-bottom: 0.5px solid #cdfca1;
}
@media (max-width: 767.98px) {
  .title-note {
    width: 30%;
  }
  .content-note {
    width: 70%;
  }
  .header-table,
  ::v-deep .table td {
    font-size: 9px;
  }
  ::v-deep .table th {
    padding: 0.5rem;
  }
  /* p {
    font-size: 8px;
  }
  span {
    font-size: 8px;
  } */
  header-title {
    font-size: 8px;
  }
}
</style>
